<template>
	<div class="p-2 breadcrumbs hidden-xs" :class="{ 'd-none': !$route.path.startsWith('/triedy') }">
		<div class="margin">
			<p class="d-flex cursor-pointer font-weight-bold" @click="$router.push('/profil')">Úvod</p>
			<div>
				<span class="mx-2">&bull;</span>
				<p class="d-flex" :class="{'cursor-pointer font-weight-bold' : isEmptyParams()}"
					@click="isEmptyParams() && $router.push('/triedy')">Triedy
				</p>
			</div>
			<div v-if="groupName">
				<span class="mx-2">&bull;</span>
				<p :class="[studentName || $route.params.taskId ||  $route.query.tabIndex != 0 ? 'font-weight-bold cursor-pointer' : '']"
					@click="($route.params.taskId || $route.params.studentId)
						&& $router.replace({name: 'Trieda', params: {id: $route.params.id}, query: {tabIndex: 1}})
						|| $route.query.tabIndex == '1' && $router.push({query: {tabIndex: 0}})">
					{{groupName}}
				</p>
			</div>
			<div v-if="studentName">
				<span class="mx-2">&bull;</span>
				<p>{{studentName}}</p>
			</div>
			<div v-if="$route.query.tabIndex == 1 || $route.params.taskId">
				<span class="mx-2">&bull;</span>
				<p :class="{'cursor-pointer font-weight-bold': $route.params.taskId}"
					@click="$route.params.taskId && $router.push(`/triedy/${$route.params.id}?tabIndex=1`)">Úlohy
				</p>
			</div>
			<div v-if="taskName">
				<span class="mx-2">&bull;</span>
				<p>{{taskName}}</p>
			</div>
		</div>
	</div>
</template>

<script>
import { isEmpty } from 'lodash'
import { mapGetters } from 'vuex'
import wAxios from '@/plugins/w/axios'

const api = {
	admin: {
		getGroup: (gid)	=> wAxios.get_auth_data	 (`v1/group-admin/groups/${gid}`),
		getMember: (gid, mid) => wAxios.get_auth_data(`v1/group-admin/groups/${gid}/members/${mid}/tasks`),
		getTask: (gid, tid) => wAxios.get_auth_data(`v1/group-admin/groups/${gid}/tasks/${tid}`)
	},
	member: {
		getGroup: (gid) => wAxios.get_auth_data(`v1/group-member/groups/${gid}`),
		getTask: (gid, tid) => wAxios.get_auth_data(`v1/group-member/groups/${gid}/tasks/${tid}`),
	}
}

export default {
	data() {
		return {
			groupName: null,
			studentName: null,
			taskName: null,
			atTasks: false
		}
	},
	watch: {
		'$route.params': {
			immediate: true,
			handler(val) {
				val.id ? this._loadGroupName(val.id) : this.groupName = null
				val.studentId ? this._loadlStudentName(val.id, val.studentId) : this.studentName = null
				val.taskId ? this._loadTaskName(val.id, val.taskId) : this.taskName = null
			}
		},
		'$route.query.tabIndex': {
			immediate: true,
			handler(tabIndex) {
				this.atTasks = tabIndex ? true : false
			}
		}
	},
	computed: {
		...mapGetters('wAuth', [
			'user'
		]),
		isAdmin() {
			return this.user.profile.type == 'teacher'
		}
	},
	methods: {
		isEmptyParams() {
			return !isEmpty(this.$route.params)
		},
		async _loadGroupName(gid) {
			const handler = this.isAdmin ? api.admin.getGroup : api.member.getGroup
			const group = await handler(gid)
			this.groupName = `${group.name} ${group.second_name}`
		},
		async _loadlStudentName(gid, mid) {
			const student = await api.admin.getMember(gid, mid)
			this.studentName = `${student.user.name} ${student.user.surname}`
		},
		async _loadTaskName(gid, tid) {
			const handler = this.isAdmin ? api.admin.getTask : api.member.getTask
			const task = await handler(this.$route.params.id ,this.$route.params.taskId)
			this.taskName = task.course.name
		},
	}
}
</script>

<style lang="scss" scoped>
	.breadcrumbs {
		.margin {
			padding-left: 20%;
		}
		position: absolute;
		width: 100%;
		background-color: #ffffff;
		border-top: 1px solid #DFE0E4;
		border-bottom: 1px solid #DFE0E4;
	}

	div {
		display: flex;
	}

	p {
		margin: 0;
		color: var(--a-color-black);
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.font-weight-bold {
		color: var(--a-color-black);
	}

	@media only screen and (max-width: 600px) {
		.breadcrumbs {
			display: none !important;
		}
	}
</style>